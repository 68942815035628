import axios from 'axios';
import { getConfig } from '../data/config';
import { generateUUID } from '../data/generateUuid';

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(config => {
  config.headers = config.headers || {};
  if (getConfig()) {
    const webConfig = getConfig();
    config.headers['Msg-Ctx-Env'] = webConfig.CtxEnv;
    config.headers['Msg-Ctx-Src'] = webConfig.CtxSrc;
    config.headers['Msg-Ctx-Src-Module'] = 'web'; // hardcode: web
  }
  config.headers['Msg-Ctx-Id'] = generateUUID();
  config.headers['Msg-Ctx-Corr-Id'] = generateUUID();
  return config;
});
