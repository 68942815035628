import { Box, BoxProps, Button, errorIcon, Loader, successIcon } from '@ytl/common-web';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/languageContext';
import { AppRoutes } from '../routes';
import { PasswordSetResult } from '../shared/@types/passwordSetResult';
import { SetPassword } from '../shared/components/custom/set-password/SetPassword';
import { missingSessionIdBoxConfig } from '../shared/data/boxConfig';
import { PasswordResource } from '../shared/resource/password.resource';
import { usePassword } from '../shared/utils/usePassword';
import { useQueryParams } from '../shared/utils/useQueryParams';
import { SessionIdCheckState, useSessionIdCheck } from '../shared/utils/useSessionIdCheck';

export const InitialPasswordSetPage = () => {
  const { translation } = useLanguage();
  const navigate = useNavigate();
  const query = useQueryParams();
  const [passwordSetResult, setPasswordSetResult] = useState<PasswordSetResult>(PasswordSetResult.NA);
  const {
    password,
    setPassword,
    passwordAgain,
    setPasswordAgain,
    isLoading,
    setLoading,
    isContinueButtonDisabled,
    hasPasswordError,
    validatePasswords,
    getPasswordHints,
    passwordsAreNotMatching,
  } = usePassword();
  const { sessionIdCheckState, checkSession, token, accountId } = useSessionIdCheck(setLoading);

  const isCheckout = query.get('checkout') === 'true';
  if (
    !isCheckout &&
    sessionIdCheckState === SessionIdCheckState.CheckedAndValid &&
    passwordSetResult !== PasswordSetResult.Failed
  ) {
    // console.log(`redirect to alogin for account ${accountId} with email ${tokenEmail}`);

    (async () => {
      await PasswordResource.setInitialPassword(token, accountId);
    })();
  }

  const continueClicked = useCallback(async () => {
    const sessionId = query.get('session-id');
    if (!validatePasswords() || !sessionId) {
      return;
    }
    try {
      setLoading(true);
      await PasswordResource.setInitialPassword(token, accountId, password);
      setPasswordSetResult(PasswordSetResult.Succeeded);
    } catch (err) {
      setPasswordSetResult(PasswordSetResult.Failed);
    } finally {
      setLoading(false);
    }
  }, [query, setLoading, token, accountId, validatePasswords, setPasswordSetResult, password]);

  const tryAgainClicked = useCallback(() => setPasswordSetResult(PasswordSetResult.NA), []);

  const continueToAccountClicked = useCallback(() => {
    navigate(AppRoutes.homepage);
  }, [navigate]);

  const boxConfig: Omit<BoxProps, 'children'> = useMemo(() => {
    if (sessionIdCheckState === SessionIdCheckState.NotChecked) {
      return {
        headerHTML: translation.initialPasswordSet.header,
        subHeader: translation.initialPasswordSet.subHeaderDuringLoading,
        action: (
          <Button disabled={true}>
            <Loader isLoading={isLoading} />
          </Button>
        ),
      };
    }
    if (sessionIdCheckState === SessionIdCheckState.Missing) {
      return missingSessionIdBoxConfig(translation);
    }
    if (sessionIdCheckState === SessionIdCheckState.CheckedAndInvalid) {
      return {
        ...errorIcon,
        headerHTML: translation.initialPasswordSet.invalidSession.header,
        subHeader: translation.initialPasswordSet.invalidSession.subHeader,
        action: <Button onClick={() => AppRoutes.goToYettel()}>{translation.header.menu.backToYettel}</Button>,
      };
    }

    if (passwordSetResult === PasswordSetResult.NA) {
      return {
        headerHTML: translation.initialPasswordSet.header,
        subHeader: translation.initialPasswordSet.subHeader,
        action: (
          <Button
            disabled={isContinueButtonDisabled || !password || !passwordAgain}
            onClick={continueClicked}
          >
            <Loader isLoading={isLoading} />
            {translation.initialPasswordSet.createAccount}
          </Button>
        ),
      };
    }
    if (passwordSetResult === PasswordSetResult.Succeeded) {
      return {
        ...successIcon,
        headerHTML: translation.initialPasswordSet.success.header,
        subHeader: translation.initialPasswordSet.success.subHeader,
        action: <Button onClick={continueToAccountClicked}>{translation.initialPasswordSet.continueToAccount}</Button>,
      };
    }
    return {
      ...errorIcon,
      headerHTML: translation.initialPasswordSet.error.header,
      subHeader: translation.initialPasswordSet.error.subHeader,
      action: <Button onClick={tryAgainClicked}>{translation.initialPasswordSet.tryAgain}</Button>,
    };
  }, [
    sessionIdCheckState,
    passwordSetResult,
    translation,
    tryAgainClicked,
    isContinueButtonDisabled,
    continueClicked,
    isLoading,
    continueToAccountClicked,
    password,
    passwordAgain,
  ]);

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  return (
    <div className="password-page">
      <Box {...boxConfig}>
        {sessionIdCheckState === SessionIdCheckState.CheckedAndValid &&
          passwordSetResult === PasswordSetResult.NA &&
          isCheckout && (
            <SetPassword
              password={password}
              passwordAgain={passwordAgain}
              passwordHints={getPasswordHints(password, passwordAgain)}
              hasPasswordError={hasPasswordError}
              passwordsAreNotMatching={passwordsAreNotMatching}
              setPassword={setPassword}
              setPasswordAgain={setPasswordAgain}
              isLoading={isLoading}
              onEnter={continueClicked}
            />
          )}
      </Box>
    </div>
  );
};
