export const MIDDLEWARE_ERROR_CODES = {
  EMAIL_ADDRESS_NOT_AVAILABLE: 'error-email-address-not-available',
  INVALID_EMAIL_FORMAT: 'invalid-email-format',
  INVALID_OTP_CODE: 'invalid-otp-code',
  INVALID_SESSION_ID: 'invalid-session-id',
  INVALID_FLOW_TYPE: 'invalid-flow-type',
  INVALID_FUTURE_CHANGE: 'invalid-future-change',
  UNKNOWN_TMS_MTA_ERROR: 'unknown-tms-mta-error',
  UNKNOWN_BACKEND_ERROR: 'unknown-backend-error',

};
