import { useModeParams, useTheme } from '@ytl/common-web';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../contexts/languageContext';

export function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useLinkParams() {
  const { isYolo } = useModeParams();
  const { isDark, isWebView } = useTheme();
  const { language } = useLanguage();
  return useMemo<string>(() => {
    return [isDark ? `theme=dark` : '', isWebView ? `app=true` : '', isYolo ? `mode=yolo` : '', `locale=${language}`]
      .filter(s => !!s)
      .join('&');
  }, [isDark, isWebView, isYolo, language]);
}
