import { LanguageProvider, ThemeProvider } from '@ytl/common-web';
import React from 'react';
import { Provider } from 'react-redux';
import { translationEn, translationHu } from '../shared/data/translation';
import store from '../store';
import { AppProvider } from './appContext';
import { AuthProvider } from './authContext';

export type ContextProviderProps = { children: React.ReactNode };

const ContextProvider = ({ children }: ContextProviderProps) => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <LanguageProvider
          translationHu={translationHu}
          translationEn={translationEn}
        >
          <AppProvider>
            <AuthProvider>{children}</AuthProvider>
          </AppProvider>
        </LanguageProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default ContextProvider;
