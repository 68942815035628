import {
  AccountDTO,
  CheckSessionResp,
  SetInitialPasswordReqBody,
  SetPasswordReqBody,
  UserDTO,
} from '@ytl/common-model';
import { getConfig } from '../data/config';
import { axiosInstance } from './axiosInstance';

export const PasswordResource = {
  async checkInitPasswordSessionId(token: string): Promise<CheckSessionResp> {
    return (await axiosInstance.get(`/api/check-session-expiry?token=${token}`)).data;
  },

  async setInitialPassword(token: string, accountId: string, newPassword?: string): Promise<AccountDTO> {
    const res = await axiosInstance.post(
      `/api/set-initial-password`,
      {
        token,
        accountId,
        newPassword,
      },
      { maxRedirects: 0 },
    );

    if (res.headers?.location) {
      window.location.href = res.headers.location;
    }

    return res.data as UserDTO;
  },

  changePassword(params: SetPasswordReqBody): Promise<void> {
    return axiosInstance.post<SetPasswordReqBody, void>(`/api/change-password`, params);
  },

  forgotPassword(params: SetInitialPasswordReqBody): Promise<void> {
    return axiosInstance.post<SetInitialPasswordReqBody, void>(`/api/forgot-password`, params);
  },

  sendForgottenPasswordEmail(email: string): Promise<boolean> {
    return axiosInstance.post(`/api/start-forgotten-password`, { email });
  },
  closeWebView() {
    window.location.href = getConfig().closeWebViewUrl;
  },
};
