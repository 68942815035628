import { Button } from '@ytl/common-web';
import { ReactElement } from 'react';

/** @deprecated */
export interface DialogProps {
  title: string;
  subHeader?: string;
  content?: string;
  icon?: ReactElement;
  cancelLabel?: string;
  okLabel?: string;
  dangerLabel?: string;
}

/** @deprecated */
export enum DialogCloseReason {
  Cancel = 'cancel',
  Ok = 'ok',
  Danger = 'Danger',
}

/** @deprecated */
export interface DialogWrapperProps extends DialogProps {
  onClose: (reason: DialogCloseReason) => void;
}

/** @deprecated */
export const noDialog = ({
  onClose,
  icon,
  content,
  title,
  subHeader,
  okLabel,
  cancelLabel,
  dangerLabel,
}: DialogWrapperProps) => {
  return (
    <>
      <div className="dialog-backdrop"></div>
      <div className="dialog-wrapper">
        <div className="dialog">
          <div className="dialog__icon">{icon}</div>
          <div className="dialog__header">{title}</div>
          {subHeader && <div className="dialog__sub-header">{subHeader}</div>}
          <div className={`dialog__body${!content ? ' dialog__body--empty' : ''}`}>{!!content && content}</div>
          <div className="dialog__actions">
            {cancelLabel && (
              <Button
                variant={'outlined'}
                onClick={() => onClose(DialogCloseReason.Cancel)}
              >
                {cancelLabel}
              </Button>
            )}
            {okLabel && (
              <Button
                variant={'contained'}
                onClick={() => onClose(DialogCloseReason.Ok)}
              >
                {okLabel}
              </Button>
            )}
            {dangerLabel && (
              <Button
                variant={'contained'}
                color={'danger'}
                onClick={() => onClose(DialogCloseReason.Danger)}
              >
                {dangerLabel}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
