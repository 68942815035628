import { Box, BoxProps, Button } from '@ytl/common-web';
import { useMemo } from 'react';
import { useLanguage } from '../contexts/languageContext';
import { AppRoutes } from '../routes';

export const NotConfirmedEmailPage = () => {
  const { translation } = useLanguage();

  const boxConfig: Omit<BoxProps, 'children'> = useMemo(() => {
    return {
      headerHTML: translation.notConfirmedEmail.header,
      subHeader: translation.notConfirmedEmail.subHeader,
      action: <Button onClick={() => AppRoutes.goToYettel()}>{translation.notConfirmedEmail.action}</Button>,
    };
  }, [translation]);

  return (
    <div className="password-page">
      <Box {...boxConfig} />
    </div>
  );
};
