import { RegistrationMode } from '@ytl/common-model';

import { Analytics, useTheme } from '@ytl/common-web';
import { useEffect, useLayoutEffect } from 'react';
import { RegisterView } from '../modules/register/RegisterView';
import { RegisterWebView } from '../modules/register/RegisterWebView';
import { useQueryParams } from '../shared/utils/useQueryParams';

export enum RegistrationPhase {
  Form = 'form',
  Result = 'result',
  EmailOTP = 'emailOtp',
  RegistrationComplete = 'registrationComplete',
  RegistrationFailed = 'registrationFailed',
}

export const RegisterPage = ({ isRawLayout }: { isRawLayout: boolean }) => {
  const query = useQueryParams();
  const registrationMode = (query.get('mode')?.toLowerCase() as RegistrationMode) || RegistrationMode.Default;
  const { isWebView } = useTheme();

  useLayoutEffect(() => {
    document.documentElement.classList.add('register-page');
    if (isRawLayout) {
      document.documentElement.classList.add('register-page--raw');
    }
    return () => {
      document.documentElement.classList.remove('register-page');
      document.documentElement.classList.remove('register-page--raw');
    };
  }, [isRawLayout]);

  useEffect(() => {
    Analytics.pageView(isWebView);
  }, [isWebView]);

  //const accountData = await authResource.getAccount();

  return (
    <>
      {isWebView && <RegisterWebView registrationMode={registrationMode} />}
      {!isWebView && <RegisterView registrationMode={registrationMode} />}
    </>
  );
};
