import { MFACreateQrCodeResponse, SmsConnectReqBody, TotpConnectReqBody } from '@ytl/common-model';
import { AxiosResponse } from 'axios';
import { axiosInstance } from './axiosInstance';

export const mfaResource = {
  checkSmsNumber(phoneNumber: string) {
    return axiosInstance.post(`/api/mfa/check-sms-number`, { phoneNumber });
  },
  validateSmsCode(code: string) {
    return axiosInstance.post(`/api/mfa/validate-sms-code`, { code });
  },
  createQrCode(email: string, authenticatorName: string): Promise<AxiosResponse<MFACreateQrCodeResponse>> {
    return axiosInstance.post(`/api/mfa/create-qr-code`, { email, authenticatorName });
  },
  disconnect: () => {
    return axiosInstance.post(`/api/mfa/disconnect`, {});
  },
  async getTotpConnectUrl(data: TotpConnectReqBody): Promise<string> {
    try {
      const res = await axiosInstance.post(`/api/mfa/connect/totp`, data);
      return res.data as string;
    } catch (e) {
      console.error(e);
      return '#';
    }
  },

  async getSmsConnectUrl(data: SmsConnectReqBody): Promise<string> {
    try {
      const res = await axiosInstance.post(`/api/mfa/connect/sms`, data);
      return res.data as string;
    } catch (e) {
      console.error(e);
      return '#';
    }
  },
};
