import { UserDTO } from '@ytl/common-model';
import { AxiosError } from 'axios';
import { AppRoutes } from '../../routes';
import { axiosInstance } from './axiosInstance';

function getAuthHeader() {
  return {
    'X-Requested-With': 'XMLHttpRequest',
  };
}

export const authResource = {
  async getAccount(forwardToLogin = true): Promise<UserDTO> {
    if (process.env.REACT_APP_TEST_USER_LOGGED_IN === 'true') {
      return {
        accountId: '123',
        email: 'test@example.com',
        name: 'Test User',
        assignedSubscriptions: [],
      };
    }
    try {
      const accountResponse = await axiosInstance.get('/api/account', { headers: getAuthHeader() });
      const redirectUrl = accountResponse.data?.redirectUrl?.toString();
      if (accountResponse.data?.smsLogin && !window.location.href.includes(redirectUrl)) {
        if (redirectUrl.startsWith('http')) {
          window.location.href = redirectUrl;
        } else if (redirectUrl.startsWith('/')) {
          window.location.href = window.location.origin + redirectUrl + window.location.search;
        } else {
          window.location.href = AppRoutes.errorPage;
        }
      }
      return accountResponse.data;
    } catch (err) {
      if ((err as AxiosError).response?.status === 401 && (err as AxiosError).response?.headers.location) {
        if (forwardToLogin) {
          window.location.href = (err as AxiosError).response?.headers.location as string;
        }
      }
      throw err;
    }
  },
};
