import { getConfig } from './shared/data/config';

export const AppRoutes = {
  homepage: '/',
  mfaSetup: '/mfa-beallitas',
  registrationCompleted: '/regisztracio-kesz',
  notfound: '/404',
  errorPage: '/hiba',

  initialPasswordSet: '/regisztracio-veglegesitese',
  forgottenPassword: {
    _: '/elfelejtett-jelszo',
    withEmail: (email: string) => `/elfelejtett-jelszo?email=${email}`,
  },
  //mar beloggolt user szamara "jelszo modositas"
  changePassword: '/jelszo-valtoztatas',
  //be nem lepett user szamara "elfelejtett jelszo tms sessionidvel"
  forgottenPasswordSet: '/jelszo-beallitas',

  emailConfirm: '/email-visszaigazolas',
  emailChange: '/email-csere',
  emailChangeBlock: '/email-csere-tiltas',

  smsLoginError: '/sms-belepes-hiba',

  register: '/regisztracio',

  nonActiveAccount: getConfig().NonActiveAccountUrl,

  isAuthenticatedView(pathname: string) {
    return (
      [
        AppRoutes.homepage,
        AppRoutes.changePassword,
        AppRoutes.mfaSetup,
        AppRoutes.emailChange,
        AppRoutes.smsLoginError,
      ].indexOf(pathname) > -1
    );
  },

  goToYettel() {
    window.location.href = getConfig().mainYettelUrl;
  },
  goToYettelAccount() {
    window.location.href = '/';
  },
};

// EMAIL_VERIFICATION_URL=https://uat15-alm.yettel.hu/email-visszaigazolas?session-id={0}
// OTPEMAIL_VERIFICATION_URL=selfcareapp://yolo/verification?session={0}&code={1}
// PASSWORD_INIT_URL=https://uat15-alm.yettel.hu/kezdeti-jelszo-beallitas?session-id={0}
// PASSWORD_RESET_URL=https://uat15-alm.yettel.hu/jelszo-beallitas?session-id={0}
