import { AppRouting } from './AppRouting';
import ContextProvider from './contexts';

function App() {
  return (
    <ContextProvider>
      <AppRouting />
    </ContextProvider>
  );
}

export default App;
