import { Button, errorIcon } from '@ytl/common-web';
import { Translation } from '../../contexts/languageContext';
import { AppRoutes } from '../../routes';

export const missingSessionIdBoxConfig = (translation: Translation) => ({
  ...errorIcon,
  header: translation.initialPasswordSet.missingSession.header,
  subHeader: translation.initialPasswordSet.missingSession.subHeader,
  action: <Button onClick={() => AppRoutes.goToYettel()}>{translation.header.menu.backToYettel}</Button>,
});
