import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { UserDTO, UserOAuthConnection } from '@ytl/common-model';

const stateName = 'currentUser';

export enum CurrentUserFetchState {
  NA = 'NA',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Unauthorized = 'Unauthorized',
}

export interface CurrentUserState {
  isAuthenticated: null | boolean;
  currentUser?: UserDTO;
  currentUserFetched: CurrentUserFetchState;
}

export const currentUserSlice = createSlice<CurrentUserState, SliceCaseReducers<CurrentUserState>, string>({
  name: stateName,
  initialState: {
    isAuthenticated: null,
    currentUserFetched: CurrentUserFetchState.NA,
  },
  reducers: {
    setCurrentUserFetchStart(state) {
      state.currentUserFetched = CurrentUserFetchState.Loading;
    },
    setCurrentUserData(state, value: PayloadAction<UserDTO>) {
      if (value.payload) {
        state.isAuthenticated = true;
        state.currentUser = value.payload;
        state.currentUserFetched = CurrentUserFetchState.Loaded;
      } else {
        state.isAuthenticated = false;
        state.currentUser = undefined;
        state.currentUserFetched = CurrentUserFetchState.Unauthorized;
      }
    },
    updateOauthData(state, value: PayloadAction<UserOAuthConnection>) {
      if (state.currentUser) {
        state.currentUser.oauthConnection = value.payload;
      }
    },
  },
});

export const { setCurrentUserData, setCurrentUserFetchStart, updateOauthData } = currentUserSlice.actions;

export const selectCurrentUser = (state: { [stateName]: CurrentUserState }) => state[stateName].currentUser;
export const selectCurrentFetchState = (state: { [stateName]: CurrentUserState }) =>
  state[stateName].currentUserFetched;
export const selectIsAuthenticated = (state: { [stateName]: CurrentUserState }) => state[stateName].isAuthenticated;

export const currentUserSlicer = currentUserSlice.reducer;
