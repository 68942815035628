import { MIDDLEWARE_ERROR_CODES, RegistrationMode } from '@ytl/common-model';
import { useModeParams, useQueryParams, useTheme } from '@ytl/common-web';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useApp } from '../../../contexts/appContext';
import { useLanguage } from '../../../contexts/languageContext';
import { RegistrationPhase } from '../../../pages/RegisterPage';
import { AppRoutes } from '../../../routes';
import { accountResource } from '../../../shared/resource/account.resource';
import { authResource } from '../../../shared/resource/auth.resource';
import { ALMAnalytics } from '../../../shared/utils/analytics';
import { isValidEmail } from '../../../shared/utils/emailValidator';
import { usePassword } from '../../../shared/utils/usePassword';

export const useRegister = (registrationMode: RegistrationMode) => {
  const { translation } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const [registerError, setRegisterError] = useState('');
  const [view, setView] = useState(RegistrationPhase.Form);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailAlreadyUsed, setEmailAlreadyUsed] = useState<boolean>(false);

  const _usePassword = usePassword();
  const { password, validatePasswords } = _usePassword;
  const { setRegisterAccountId } = useApp();
  const location = useLocation();
  const query = useQueryParams();
  const { isWebView } = useTheme();
  const { isYolo } = useModeParams();

  useEffect(() => {
    if (location.pathname === AppRoutes.registrationCompleted) {
      if (query.get('completed') === 'true') {
        authResource
          .getAccount(false)
          .then(data => {
            ALMAnalytics.registerSuccess(isWebView, isYolo, data?.accountId);
          })
          .catch(err => {
            console.warn('Failed to fetch account data after registration.', err);
          });
        setView(RegistrationPhase.RegistrationComplete);
      } else {
        ALMAnalytics.registerFailed(isWebView, isYolo, MIDDLEWARE_ERROR_CODES.UNKNOWN_BACKEND_ERROR);
        setView(RegistrationPhase.RegistrationFailed);
      }
    }
  }, [location, query, isYolo, isWebView]);

  const register = useCallback(async () => {
    let validationFailure = false;
    if (!validatePasswords()) {
      validationFailure = true;
    }
    if (!isValidEmail(email)) {
      setEmailError(true);
      validationFailure = true;
    } else {
      setEmailError(false);
    }
    if (validationFailure) {
      setRegisterError('');
      setEmailAlreadyUsed(false);
      return;
    }

    try {
      setIsLoading(true);
      setRegisterError('');
      setEmailAlreadyUsed(false);

      const nameToStore = !!name ? name : email;
      const accountId = await accountResource.registerAccount({
        mode: registrationMode,
        name: nameToStore,
        email,
        password,
      });
      if (accountId) {
        setRegisterAccountId(accountId);
      } else {
        setRegisterError(translation.loginRegister.register.box.error);
      }

      if (registrationMode === RegistrationMode.Yolo) {
        setView(RegistrationPhase.EmailOTP);
      } else {
        setView(RegistrationPhase.Result);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const responseData = (err.response?.data as { message: string }).message;
        if (responseData === MIDDLEWARE_ERROR_CODES.EMAIL_ADDRESS_NOT_AVAILABLE) {
          ALMAnalytics.registerError(isWebView, isYolo, MIDDLEWARE_ERROR_CODES.EMAIL_ADDRESS_NOT_AVAILABLE);
          setEmailAlreadyUsed(true);
        } else if (responseData === MIDDLEWARE_ERROR_CODES.INVALID_EMAIL_FORMAT) {
          ALMAnalytics.registerError(isWebView, isYolo, MIDDLEWARE_ERROR_CODES.INVALID_EMAIL_FORMAT);
          setEmailError(true);
        } else {
          ALMAnalytics.registerFailed(isWebView, isYolo, MIDDLEWARE_ERROR_CODES.UNKNOWN_BACKEND_ERROR);
          setRegisterError(translation.loginRegister.register.box.error);
        }
      } else {
        ALMAnalytics.registerFailed(isWebView, isYolo, MIDDLEWARE_ERROR_CODES.UNKNOWN_BACKEND_ERROR);
        setRegisterError(translation.loginRegister.register.box.error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    email,
    name,
    password,
    registrationMode,
    translation,
    setRegisterAccountId,
    validatePasswords,
    isYolo,
    isWebView,
  ]);

  const updateEmail = useCallback((email: string) => {
    setEmail(email);
    setEmailError(false);
    setEmailAlreadyUsed(false);
  }, []);

  return {
    isLoading,
    setIsLoading,
    registerError,
    view,
    setView,
    name,
    setName,
    password,
    email,
    setEmail: updateEmail,
    emailError,
    emailAlreadyUsed,
    register,
    _usePassword,
  };
};
