import { MFACreateQrCodeResponse } from '@ytl/common-model';
import { Box, BoxProps, Button } from '@ytl/common-web';
import { useCallback, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import { ReactComponent as CheckIcon } from '../../../assets/check.svg';
import { useLanguage } from '../../../contexts/languageContext';

export const useMfaAuthenticatorQrCodeView = (qrCode: MFACreateQrCodeResponse | undefined, onContinue: () => void) => {
  const { translation } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);

  const secret = qrCode?.secret || '';
  const totp = qrCode?.totp || '';

  const continueClicked = useCallback(async () => {
    try {
      setIsLoading(true);
      onContinue();
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, onContinue]);

  const boxConfig: Omit<BoxProps, 'children'> = useMemo(() => {
    return {
      header: translation.accountSettings.mfa.authenticatorCodeGen.title,
      subHeader: translation.accountSettings.mfa.authenticatorCodeGen.content,
      icon: <CheckIcon className={'icon'} />,
      action: (
        <Button
          disabled={isLoading}
          onClick={() => continueClicked()}
        >
          {translation.accountSettings.mfa.authenticatorCodeGen.continue}
        </Button>
      ),
    };
  }, [translation, continueClicked, isLoading]);

  const view = useMemo(
    () => (
      <Box {...boxConfig}>
        <div className="qr-code__container">
          <div className="qr-code__container__qr-wrapper">
            <QRCode
              value={totp}
              size={180}
            />
          </div>

          <div className="qr-code__container__raw-code-container">
            <span className="qr-code__container__raw-code">{secret}</span>
            <Button
              variant="text"
              onClick={() => {
                navigator.clipboard.writeText(secret);
              }}
            >
              {translation.accountSettings.mfa.authenticatorCodeGen.copy}
            </Button>
          </div>
        </div>
      </Box>
    ),
    [boxConfig, translation, secret, totp],
  );

  return {
    view,
  };
};
