import { ReactElement, useMemo } from 'react';
import { Tooltip as TippyTooltip } from 'react-tippy';

export interface TooltipItems {
  icon: ReactElement;
  text?: string;
  html?: string | ReactElement;
  includeIconInTooltip?: boolean;
}

export const Tooltip = ({ icon, text, html, includeIconInTooltip }: TooltipItems) => {
  const displayedText = useMemo(() => {
    if (html && typeof html === 'string') {
      return (
        <div
          className="tippy-tooltip-content__text"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    }
    return <div className="tippy-tooltip-content__text">{html || text}</div>;
  }, [html, text]);
  return (
    <>
      <TippyTooltip
        position="top-start"
        trigger="mouseenter"
        arrow={true}
        style={{ fontSmooth: 'auto' }}
        html={
          <>
            {includeIconInTooltip && <div className="tippy-tooltip-content__icon">{icon}</div>}
            {displayedText}
          </>
        }
      >
        {icon}
      </TippyTooltip>
    </>
  );
};
