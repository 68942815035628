import { ReactElement } from 'react';

export interface DataCardProps {
  header: string | ReactElement;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const DataCard = ({ header, children, style }: DataCardProps) => {
  return (
    <>
      <div
        className="data-card"
        style={!!style ? style : {}}
      >
        <div className="data-card__header">{header}</div>
        <div className="data-card__content">{children}</div>
      </div>
    </>
  );
};
