import { RegistrationMode } from '@ytl/common-model';
import { Button, Input, InputMaxPasswordLength, makeLink, Tooltip, useModeParams } from '@ytl/common-web';
import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as Envelope } from '../../assets/envelope.svg';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { useApp } from '../../contexts/appContext';
import { useLanguage } from '../../contexts/languageContext';
import { RegistrationPhase } from '../../pages/RegisterPage';
import { AppRoutes } from '../../routes';
import { getConfig } from '../../shared/data/config';
import { accountResource } from '../../shared/resource/account.resource';
import { useLinkParams } from '../../shared/utils/useQueryParams';
import { RegisterYoloOtpView } from './RegisterYoloOtpView';
import { useRegister } from './service/useRegister';

export type RegisterInAppProps = {
  registrationMode: RegistrationMode;
};

export const RegisterWebView = ({ registrationMode }: RegisterInAppProps) => {
  const { translation } = useLanguage();
  const {
    isLoading,
    setIsLoading,
    registerError,
    view,
    setView,
    email,
    setEmail,
    emailError,
    emailAlreadyUsed,
    register,
    _usePassword,
  } = useRegister(registrationMode);
  const { password, setPassword, passwordAgain, setPasswordAgain, passwordErrors } = _usePassword;
  const registerButtonEnabled = email.length > 0 && password.length > 0 && passwordAgain.length > 0;
  const { registerAccountId } = useApp();

  const [loginLink, setLoginLink] = useState<string>(AppRoutes.homepage);

  // const { isDark, isWebView } = useTheme();
  const { isYolo } = useModeParams();
  const linkParams = useLinkParams();
  // const linkParams = useMemo(() => {
  //   return [isDark ? `theme=dark` : '', isWebView ? `app=true` : '', isYolo ? `mode=yolo` : '', `locale=${language}`]
  //     .filter(s => !!s)
  //     .join('&');
  // }, [isDark, isWebView, isYolo, language]);

  const fetchLoginLink = useMemo(
    () => async () => {
      if (emailAlreadyUsed) {
        const loginLink = await getLoginUrl();
        // const link = makeLink(loginLink, linkParams);
        if (loginLink) {
          setLoginLink(loginLink);
        }
      }
    },
    [emailAlreadyUsed, setLoginLink],
  );
  useEffect(() => {
    fetchLoginLink();
  }, [fetchLoginLink]);

  const getLoginUrl = async () => {
    return await accountResource.getRegisterLoginUrl();
  };
  const loginWithYolo = () => {
    (async () => {
      const url = await getLoginUrl();
      if (url) {
        window.location.href = url;
      }
    })();
  };

  const forgottenPasswordLink = useMemo(() => {
    const baseLink = AppRoutes.forgottenPassword.withEmail(encodeURIComponent(email));
    const link = makeLink(baseLink, linkParams);
    if (link) {
      return link;
    } else {
      return baseLink;
    }
  }, [email, linkParams]);
  const getPasswordErrorList = useMemo(() => {
    if (!passwordErrors) {
      return [];
    }
    if (!passwordErrors.hasLowerCaseLetter) {
      return [translation.password.errors.lowerCase];
    }
    if (!passwordErrors.hasUpperCaseLetter) {
      return [translation.password.errors.upperCase];
    }
    if (!passwordErrors.hasNumber) {
      return [translation.password.errors.atLeastANumber];
    }
    if (!passwordErrors.hasSpecialCharacter) {
      return [translation.password.errors.atLeastASpecial];
    }
    if (!passwordErrors.lengthIsCorrect) {
      return [translation.password.errors.lengthCheck];
    }
    if (!passwordErrors.passwordsAreMatching) {
      return [translation.passwordSet.error.doesntMatch];
    }
    return [];
  }, [passwordErrors, translation]);

  return (
    <>
      <div className="register-page">
        <div className="register-page__container">
          {view === RegistrationPhase.Form && (
            <div className="register-page__container__box">
              <h3>{translation.loginRegister.registerWebView.box.header}</h3>
              <p>{translation.loginRegister.registerWebView.box.title}</p>

              <div className={'register-page__container__box__inputs'}>
                <Input
                  label={translation.loginRegister.register.box.email}
                  type={'email'}
                  name={'email'}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  errorList={emailError ? [translation.loginRegister.register.box.errorInvalidEmail] : []}
                  warningList={
                    emailAlreadyUsed
                      ? [
                        <span
                          dangerouslySetInnerHTML={{
                            __html: translation.loginRegister.register.box.errorAccountAlreadyExists(
                              loginLink,
                              forgottenPasswordLink,
                            ),
                          }}
                        />,
                      ]
                      : []
                  }
                />
                <Input
                  label={
                    <>
                      {translation.loginRegister.register.box.password}
                      <Tooltip
                        html={translation.loginRegister.register.box.passwordTooltip}
                        icon={<InfoIcon className={'icon icon--tooltip'} />}
                        includeIconInTooltip={true}
                      />
                    </>
                  }
                  type={'password'}
                  name={'password'}
                  value={password}
                  maxLength={InputMaxPasswordLength}
                  onChange={e => setPassword(e.target.value)}
                  hasError={getPasswordErrorList.length > 0}
                />

                <Input
                  label={
                    <>
                      {translation.loginRegister.register.box.passwordAgain}
                      <Tooltip
                        html={translation.loginRegister.register.box.passwordTooltip}
                        icon={<InfoIcon className={'icon icon--tooltip'} />}
                        includeIconInTooltip={true}
                      />
                    </>
                  }
                  type={'password'}
                  name={'passwordAgain'}
                  value={passwordAgain}
                  maxLength={InputMaxPasswordLength}
                  onChange={e => setPasswordAgain(e.target.value)}
                  errorList={getPasswordErrorList}
                />

                <p className="register-page__container__box__password-hint">
                  {translation.loginRegister.register.box.passwordHint}
                </p>
              </div>

              {registerError && <div className="register-page__container__error">{registerError}</div>}

              <div
                className="register-page__container__action-container register-page__container__action-container--webview">
                <Button
                  variant={'contained'}
                  disabled={!registerButtonEnabled || isLoading}
                  onClick={register}
                >
                  {translation.loginRegister.registerWebView.box.continue}
                </Button>
              </div>
              {isYolo && (
                <>
                  <div className="register-page__container__divider">
                    <span>{translation.loginRegister.register.box.oauthSectionLabel}</span>
                  </div>

                  <Button
                    variant={'outlined'}
                    onClick={() => loginWithYolo()}
                  >
                    {translation.loginRegister.register.box.loginWithYolo}
                  </Button>
                </>
              )}
            </div>
          )}
          {view === RegistrationPhase.EmailOTP && (
            <div className="register-page__container__box register-page__container__box--otp">
              <RegisterYoloOtpView
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                onSendAgain={() => register()}
                onCancelView={() => setView(RegistrationPhase.Form)}
                onComplete={() => {
                  return; /*setView(RegistrationPhase.Result)*/
                }}
                isWebView={true}
                callback={otpCode => accountResource.confirmRegistrationOTP(registerAccountId, otpCode)}
                header={translation.loginRegister.otp.header}
                title={translation.loginRegister.otp.title}
              />
            </div>
          )}
          {view === RegistrationPhase.Result && (
            <div className="register-page__container__box">
              <h3>{translation.loginRegister.register.result.header}</h3>
              <div className={'register-page__container__icon-result'}>
                <Envelope className={'icon'} />
                <span>{translation.loginRegister.register.result.subHeader}</span>
              </div>
              <p className={'register-page__container__result-desc'}>
                {translation.loginRegister.register.result.desc}
                <Button
                  variant={'text'}
                  onClick={() => setView(RegistrationPhase.Form)}
                  className="button--no-left-right-padding"
                >
                  {translation.loginRegister.register.result.resend}
                </Button>
              </p>

              <Button
                variant={'outlined'}
                onClick={() => (window.location.href = getConfig().closeWebViewUrl)}
              >
                {translation.loginRegister.register.result.backToYettelAccount}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
