import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authResource } from '../shared/resource/auth.resource';
import {
  CurrentUserFetchState,
  selectCurrentFetchState,
  setCurrentUserData,
  setCurrentUserFetchStart,
} from './currentUserSlicer';

import { Loader } from '@ytl/common-web';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../routes';

const AuthProvider = (props: { children: React.ReactNode }) => {
  const [isConfigLoaded, setConfigLoaded] = useState(false);
  const currentUserFetchState = useSelector(selectCurrentFetchState);
  const location = useLocation();
  const dispatch = useDispatch();

  const checkLogin = useCallback(async () => {
    try {
      if (AppRoutes.isAuthenticatedView(location.pathname) && currentUserFetchState === CurrentUserFetchState.NA) {
        try {
          dispatch(setCurrentUserFetchStart(null));
          const accountData = await authResource.getAccount();
          dispatch(setCurrentUserData(accountData));
        } catch {
          dispatch(setCurrentUserData(null));
        }
      }
    } catch {
      console.error('config failed to load, this is very bad');
    } finally {
      setConfigLoaded(true);
    }
  }, [location, currentUserFetchState, dispatch]);

  useEffect(() => {
    checkLogin();
  }, [isConfigLoaded, checkLogin]);

  return (
    <>
      {!isConfigLoaded && <Loader isLoading={true} />}
      {isConfigLoaded && <>{props.children}</>}
    </>
  );
};

export { AuthProvider };
