import {
  Analytics,
  Box,
  BoxProps,
  Button,
  errorIcon,
  Loader,
  successIcon,
  useModeParams,
  useTheme,
} from '@ytl/common-web';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/languageContext';
import { AppRoutes } from '../routes';
import { accountResource } from '../shared/resource/account.resource';
import { ALMAnalytics } from '../shared/utils/analytics';
import { useQueryParams } from '../shared/utils/useQueryParams';

enum EmailConfirmState {
  NA,
  Confirmed,
  Failed,
}

export const EmailConfirmPage = () => {
  const { translation } = useLanguage();
  const navigate = useNavigate();
  const query = useQueryParams();
  const sessionId = query.get('session-id');
  const { isWebView } = useTheme();
  const { isYolo } = useModeParams();
  const [emailConfirmedState, setEmailConfirmedState] = useState(EmailConfirmState.NA);

  const boxConfig: Omit<BoxProps, 'children'> = useMemo(() => {
    if (emailConfirmedState === EmailConfirmState.NA) {
      return {
        header: translation.emailConfirm.header,
        subHeader: translation.emailConfirm.subHeader,
        action: (
          <Button disabled={true}>
            <Loader isLoading={true} />
          </Button>
        ),
      };
    }
    if (emailConfirmedState === EmailConfirmState.Failed) {
      if (!sessionId) {
        return {
          ...errorIcon,
          headerHTML: translation.emailConfirm.missingSession.header,
          subHeader: translation.emailConfirm.missingSession.subHeader,
          action: (
            <Button onClick={() => AppRoutes.goToYettel()}>{translation.emailConfirm.error.continueToYettel}</Button>
          ),
        };
      }
      return {
        ...errorIcon,
        headerHTML: translation.emailConfirm.error.header,
        subHeader: translation.emailConfirm.error.subHeader,
        action: (
          <Button onClick={() => AppRoutes.goToYettel()}>{translation.emailConfirm.error.continueToYettel}</Button>
        ),
      };
    }
    return {
      ...successIcon,
      header: translation.emailConfirm.success.header,
      subHeader: translation.emailConfirm.success.subHeader,
      action: (
        <Button onClick={() => navigate(AppRoutes.homepage)}>{translation.emailConfirm.success.continueToHome}</Button>
      ),
    };
  }, [sessionId, emailConfirmedState, navigate, translation]);
  useEffect(() => {
    Analytics.pageView(isWebView);
  }, [isWebView]);
  useEffect(() => {
    (async () => {
      try {
        if (!sessionId) {
          throw new Error('Empty token');
        }
        const res = await accountResource.confirmEmail(sessionId);
        if (res?.isRegistrationFlow) {
          ALMAnalytics.registerSuccess(isWebView, isYolo, res?.account?.accountId);
        }
        setEmailConfirmedState(EmailConfirmState.Confirmed);
      } catch {
        setEmailConfirmedState(EmailConfirmState.Failed);
      }
    })();
  }, [query, sessionId, isWebView, isYolo]);

  return (
    <div className="password-page">
      <Box {...boxConfig}>
        <></>
      </Box>
    </div>
  );
};
