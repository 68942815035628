import { Button, errorIcon } from '@ytl/common-web';
import { useCallback, useState } from 'react';
import { ReactComponent as BinIcon } from '../assets/bin.svg';
import { useLanguage } from '../contexts/languageContext';
import { getConfig } from '../shared/data/config';
import { accountResource } from '../shared/resource/account.resource';
import { useSessionIdCheck } from '../shared/utils/useSessionIdCheck';

export const EmailChangeBlockPage = () => {
  const { translation } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const { sessionId } = useSessionIdCheck(setIsLoading);
  const [error, setError] = useState(false);

  const blockEmailChange = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!sessionId) {
        throw new Error('SessionId is missing');
      }
      await accountResource.blockEmailChange(sessionId);

      window.location.href = getConfig().closeWebViewUrl;
    } catch (err) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [sessionId]);

  const cancel = useCallback(() => {
    window.location.href = getConfig().closeWebViewUrl;
  }, []);

  return (
    <div className="email-change-block-page">
      {error && (
        <>
          <div className="email-change-block-page__icon">{errorIcon.icon}</div>
          <h3 dangerouslySetInnerHTML={{ __html: translation.emailChangeBlock.error.header }} />
          <p>{translation.emailChangeBlock.error.subHeader}</p>
          <Button
            disabled={isLoading}
            onClick={() => setError(false)}
          >
            {translation.emailChangeBlock.error.back}
          </Button>
        </>
      )}
      {!error && (
        <>
          <div className="email-change-block-page__icon">
            <BinIcon />
          </div>

          <h3>{translation.emailChangeBlock.header}</h3>

          <p>{translation.emailChangeBlock.content1}</p>
          {/*<p>{translation.emailChangeBlock.content2}</p>*/}

          <Button
            disabled={isLoading}
            onClick={blockEmailChange}
            variant={'contained'}
            color={'danger'}
          >
            {translation.emailChangeBlock.block}
          </Button>
          <Button
            disabled={isLoading}
            onClick={cancel}
            variant={'outlined'}
          >
            {translation.emailChangeBlock.cancel}
          </Button>
        </>
      )}
    </div>
  );
};
