import { axiosInstance } from './axiosInstance';

export const socialResource = {
  async connectUrl(provider: string) {
    try {
      const res = await axiosInstance.get(`/api/social/connect/${provider}`);
      return res.data as string;
    } catch (e) {
      console.error(e);
      return '#';
    }
  },
};
