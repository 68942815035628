import { AnalyticsEvent, AppOrWeb } from '@ytl/common-web';

export const ALMAnalytics = {
  registerSuccess: (isWebView: boolean, isYolo: boolean, accountId: string | undefined) => {
    AnalyticsEvent('registrationSuccess', {
      channel: AppOrWeb(isWebView),
      location: window.location.href,
      yettelProduct: isYolo ? 'yolo' : AppOrWeb(isWebView),
      accountId: accountId ? accountId : undefined,
    });
  },
  registerError: (isWebView: boolean, isYolo: boolean, error: string) => {
    AnalyticsEvent('registrationError', {
      channel: AppOrWeb(isWebView),
      location: window.location.href,
      yettelProduct: isYolo ? 'yolo' : AppOrWeb(isWebView),
      errorType: error,
    });
  },
  registerFailed: (isWebView: boolean, isYolo: boolean, error: string) => {
    AnalyticsEvent('registrationFailed', {
      channel: AppOrWeb(isWebView),
      location: window.location.href,
      yettelProduct: isYolo ? 'yolo' : AppOrWeb(isWebView),
      errorType: error,
    });
  },
  //
  // loginError: (isWebView: boolean, method: AnalyticsMethod, mfa: boolean | undefined, error: string) => {
  //   AnalyticsEvent('loginError', {
  //     channel: AppOrWeb(isWebView),
  //     clientId: ClientId(),
  //     location: window.location.href,
  //     method: method,
  //     sso_chain: chain(),
  //     mfa: mfa ? mfa : false,
  //     errorType: error,
  //   });
  // },

  // loginFailure: (isWebView: boolean, method: AnalyticsMethod, mfa: boolean | undefined, error: string) => {
  //   AnalyticsEvent('loginFailed', {
  //     channel: AppOrWeb(isWebView),
  //     clientId: ClientId(),
  //     location: window.location.href,
  //     method: method,
  //     sso_chain: chain(),
  //     mfa: mfa ? mfa : false,
  //     errorType: error,
  //   });
  // },
};
