import { Box, errorIcon } from '@ytl/common-web';
import { useEffect, useMemo } from 'react';
import { useApp } from '../contexts/appContext';
import { useLanguage } from '../contexts/languageContext';
import { AppRoutes } from '../routes';

export const ErrorPage = () => {
  const { translation } = useLanguage();
  const { setMenuItems } = useApp();

  const boxConfig = useMemo(
    () => ({
      ...errorIcon,
      headerHTML: translation.errorPage.header,
      subHeader: translation.errorPage.subHeader,
    }),
    [translation],
  );

  useEffect(() => {
    setMenuItems([
      {
        label: translation.header.menu.backToYettel,
        onClick() {
          AppRoutes.goToYettel();
        },
      },
    ]);
  }, [setMenuItems, translation]);

  return (
    <>
      <div className="error-page">
        <Box {...boxConfig} />
      </div>
    </>
  );
};
