import { configureStore } from '@reduxjs/toolkit';
import { currentUserSlicer } from './contexts/currentUserSlicer';

const store = configureStore({
  reducer: {
    currentUser: currentUserSlicer,
  },
});

export default store;
