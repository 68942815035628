export enum AccountRegType {
  anything = 'anything',
  unknown = 'unknown',
  WaitForFinalization = 'waitForFinalization',
  WaitForMigration = 'waitForMigration',
  Active = 'active',
  Deleted = 'deleted',
  Disabled = 'disabled',
}

export type AccountHistoryCompactDTO = {
  createdAt: Date;
  previousState: string;
  newState: string;
  initiator: string;
  comment: string;
  isBackoffice: boolean;
  userId: string;
};

export type AccountDetailsSubscriptionDTO = {
  id: string;
  assignLevel?: 'customer' | 'subscription';
  phoneNumber?: string;
  status?: 'unknown' | 'reserved' | 'active' | 'suspend' | 'cancelled';
  customerId?: string;
  subscriptionType?: 'unknown' | 'voice' | 'mbi';
  pricePlanType?: string;
};

export type AccountDetailsDTO = {
  id: string;
  email: string;
  name: string;
  regType: AccountRegType;
  // TODO I have no idea what should we map into this field, but in the UI design it's a text starting with CID:
  cid: string;
  regDate: Date;
  lastLogin: Date;
  history: Array<AccountHistoryCompactDTO>;
  subscriptions?: Array<AccountDetailsSubscriptionDTO>;
  hasMFA: boolean;
  social: {
    google: boolean;
    facebook: boolean;
    apple: boolean;
  };
};


