import { Header } from '@ytl/common-web';
import { ReactNode, useCallback } from 'react';
import { useApp } from '../../../../contexts/appContext';
import { useLanguage } from '../../../../contexts/languageContext';

export interface RegisterFrameProps {
  children: ReactNode;
}

export const RegisterFrame = ({ children }: RegisterFrameProps) => {
  const { language, setLanguage } = useLanguage();
  const { menuItems } = useApp();
  const onLanguageChange = useCallback(() => {
    if (language === 'hu') {
      setLanguage('en');
    } else {
      setLanguage('hu');
    }
  }, [language, setLanguage]);

  return (
    <>
      <div className="frame">
        <div className="frame__register-header">
          <div className="frame__header">
            <Header
              mode={'b2c'}
              menuItems={menuItems}
              language={language}
              onLanguageClick={onLanguageChange}
            ></Header>
          </div>
        </div>
        <div className="frame__content">{children}</div>
      </div>
    </>
  );
};
