import { useCallback, useMemo, useState } from 'react';
import { ReactComponent as Checked } from '../../assets/check.svg';
import { ReactComponent as Error } from '../../assets/error.svg';
import { useLanguage } from '../../contexts/languageContext';
import { passwordStrengthChecker, PasswordValidationResult } from './passwordStrengthChecker';

export const usePassword = () => {
  const { translation } = useLanguage();
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [oldPassword, checkOldPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [hasPasswordError, setHasPasswordError] = useState<boolean>(false);
  const [passwordsAreNotMatching, setPasswordsAreNotMatching] = useState<boolean>(false);
  const [passwordErrors, setPasswordErrors] = useState<PasswordValidationResult>();

  const isContinueButtonDisabled = useMemo(() => {
    return password.length === 0 || passwordAgain.length === 0 || isLoading;
  }, [password, passwordAgain, isLoading]);

  const getErrorOrSuccessIcon = useCallback((condition: boolean | undefined) => {
    if (condition) {
      return <Checked />;
    }
    return <Error />;
  }, []);

  const getErrorOrSuccessClass = useCallback((condition: boolean | undefined) => {
    if (condition) {
      return 'password-hints--success';
    } else if (condition === false) {
      return 'password-hints--error';
    }
    return '';
  }, []);

  const getPasswordHints = useCallback(
    (password: string, passwordAgain: string) => {
      const passwordValidationResult = passwordStrengthChecker(password, passwordAgain);
      return (
        <div className="password-hints">
          <p>{translation.password.passwordHint.title}</p>
          <ul>
            <li className={getErrorOrSuccessClass(passwordValidationResult?.hasLowerCaseLetter)}>
              {getErrorOrSuccessIcon(passwordValidationResult?.hasLowerCaseLetter)}{' '}
              {translation.password.passwordHint.lowerCase}
            </li>
            <li className={getErrorOrSuccessClass(passwordValidationResult?.hasUpperCaseLetter)}>
              {getErrorOrSuccessIcon(passwordValidationResult?.hasUpperCaseLetter)}{' '}
              {translation.password.passwordHint.upperCase}
            </li>
            <li className={getErrorOrSuccessClass(passwordValidationResult?.hasNumber)}>
              {getErrorOrSuccessIcon(passwordValidationResult?.hasNumber)}{' '}
              {translation.password.passwordHint.atLeastANumber}
            </li>
            <li className={getErrorOrSuccessClass(passwordValidationResult?.hasSpecialCharacter)}>
              {getErrorOrSuccessIcon(passwordValidationResult?.hasSpecialCharacter)}{' '}
              {translation.password.passwordHint.atLeastASpecial}
            </li>
            <li className={getErrorOrSuccessClass(passwordValidationResult?.lengthIsCorrect)}>
              {getErrorOrSuccessIcon(passwordValidationResult?.lengthIsCorrect)}{' '}
              {translation.password.passwordHint.lengthCheck}
            </li>
          </ul>
        </div>
      );
    },
    [translation, getErrorOrSuccessIcon, getErrorOrSuccessClass],
  );

  const validatePasswords = useCallback(() => {
    if (password.length === 0 || passwordAgain.length === 0) {
      return false;
    }

    const passwordValidationResult = passwordStrengthChecker(password, passwordAgain);
    const _hasPasswordError = passwordValidationResult
      ? passwordValidationResult.hasNumber === false ||
        passwordValidationResult.hasUpperCaseLetter === false ||
        passwordValidationResult.hasLowerCaseLetter === false ||
        passwordValidationResult.hasSpecialCharacter === false ||
        passwordValidationResult.lengthIsCorrect === false
      : false;
    const _passwordAreNotMatching = passwordValidationResult ? !passwordValidationResult.passwordsAreMatching : false;

    setHasPasswordError(_hasPasswordError);
    setPasswordsAreNotMatching(_passwordAreNotMatching);
    setPasswordErrors(passwordValidationResult);

    return !_hasPasswordError && !_passwordAreNotMatching;
  }, [password, passwordAgain]);

  const updatePassword = useCallback((val: string) => {
    setPassword(val);
    setPasswordsAreNotMatching(false);
    setPasswordErrors(undefined);
    setHasPasswordError(false);
  }, []);

  const updatePasswordAgain = useCallback((val: string) => {
    setPasswordAgain(val);
    setPasswordsAreNotMatching(false);
    setPasswordErrors(undefined);
    setHasPasswordError(false);
  }, []);

  return {
    oldPassword,
    password,
    passwordAgain,
    setPassword: updatePassword,
    setPasswordAgain: updatePasswordAgain,
    checkOldPassword,
    isLoading,
    setLoading,
    isContinueButtonDisabled,
    hasPasswordError,
    getPasswordHints,
    passwordsAreNotMatching,
    validatePasswords,
    passwordErrors,
  };
};
