import { Button } from '@ytl/common-web';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLanguage } from '../../contexts/languageContext';

export type RegisterYoloOtpViewProps = {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  onSendAgain: () => void;
  onCancelView?: () => void;
  onComplete: () => void;
  isWebView?: boolean;
  callback: (otp: string) => Promise<void>;
  header: string;
  title: string;
};

export const RegisterYoloOtpView = ({
  isLoading,
  setIsLoading,
  onSendAgain,
  onCancelView,
  onComplete,
  isWebView,
  callback,
  header,
  title,
}: RegisterYoloOtpViewProps) => {
  const { translation } = useLanguage();
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const otpInput = useRef<HTMLInputElement>(null);

  const confirmYoloOTP = useCallback(
    async (otpCode: string) => {
      try {
        setIsLoading(true);
        setError('');
        await callback(otpCode);
        onComplete();
      } catch (err) {
        setError(translation.loginRegister.otp.invalidCode);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setError, onComplete, translation, callback],
  );

  const updateOtp = useCallback(
    (value: string) => {
      setOtp(value);
      setError('');
      if (value.length === 6) {
        confirmYoloOTP(value);
      }
    },
    [setOtp, confirmYoloOTP],
  );

  const sendAgainClicked = useCallback(() => {
    onSendAgain();
    setError('');
    setOtp('');

    if (otpInput.current) {
      otpInput.current.focus();
    }
  }, [onSendAgain, setOtp]);

  useLayoutEffect(() => {
    (global as any).$enterOtpCode = (code: string) => {
      updateOtp(code);
    };
  }, [updateOtp]);

  useEffect(() => {
    if (otpInput.current) {
      otpInput.current.focus();
    }
  }, []);

  return (
    <>
      <h3>{header || translation.loginRegister.otp.header}</h3>
      {title && <p>{title}</p>}

      <div className={`form-input otp-code-input ${!!error ? 'otp-code-input--error' : ''}`}>
        <div
          className="otp-code-input__value"
          onClick={() => otpInput.current?.focus()}
        >
          <span>{otp.charAt(0) || ''}</span>
          <span>{otp.charAt(1) || ''}</span>
          <span>{otp.charAt(2) || ''}</span>
          <span>{otp.charAt(3) || ''}</span>
          <span>{otp.charAt(4) || ''}</span>
          <span>{otp.charAt(5) || ''}</span>
        </div>
        <input
          ref={otpInput}
          value={otp}
          onChange={e => updateOtp(e.target.value)}
          type="number"
          pattern={'[0-9]*'}
        />
      </div>

      {error && <div className="register-page__container__error">{error}</div>}

      <Button
        variant={'text-undecorated'}
        onClick={sendAgainClicked}
        disabled={isLoading}
      >
        {translation.loginRegister.otp.sendAgain}
      </Button>
      {!isWebView && onCancelView && (
        <Button
          variant={'text'}
          onClick={onCancelView}
          disabled={isLoading}
        >
          {translation.loginRegister.otp.back}
        </Button>
      )}
    </>
  );
};
