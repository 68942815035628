import { useCallback, useState } from 'react';
import { PasswordResource } from '../resource/password.resource';
import { useQueryParams } from './useQueryParams';

export enum SessionIdCheckState {
  NotChecked,
  Missing,
  CheckedAndValid,
  CheckedAndInvalid,
}

export const useSessionIdCheck = (setLoading: (value: boolean) => void) => {
  const query = useQueryParams();
  const sessionId = query.get('session-id');
  const [sessionIdCheckState, setSessionIdCheckState] = useState<SessionIdCheckState>(SessionIdCheckState.NotChecked);
  const [tokenEmail, setTokenEmail] = useState<string>('');
  const [token, setToken] = useState('');
  const [accountId, setAccountId] = useState('');

  const checkSession = useCallback(() => {
    if (!sessionId) {
      setSessionIdCheckState(SessionIdCheckState.Missing);
    } else {
      (async () => {
        try {
          setLoading(true);
          const res = await PasswordResource.checkInitPasswordSessionId(sessionId);
          if (!res || !res.email) {
            throw new Error('Missing email from token expiry check');
          }
          setToken(res.token);
          setTokenEmail(res.email);
          setAccountId(res.accountId);
          setSessionIdCheckState(SessionIdCheckState.CheckedAndValid);
        } catch {
          setSessionIdCheckState(SessionIdCheckState.CheckedAndInvalid);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [sessionId, setLoading]);

  return {
    sessionIdCheckState,
    checkSession,
    accountId,
    token,
    tokenEmail,
    sessionId,
  };
};
