import { AccountDetailsDTO, AccountDTO, AccountRegType, UserOAuthConnection } from '../data';

export type CheckSessionReqQuery = {
  token?: string;
};

export type CheckSessionResp = {
  email: string;
  token: string;
  accountId: string;
};

export type SetInitialPasswordReqBody = {
  token: string;
  currentPassword?: string;
  newPassword?: string;

};

export type StartForgottenPasswordReqBody = {
  email: string;
};

export type SetPasswordReqBody = {
  accountId: string;
  newPassword: string;
  // token?: string;
};

export type ConfirmEmailReqBody = {
  token: string;
};

export type UpdateAccountReqBody = {
  accountId: string;
  name?: string;
  email?: string;

};

export type ChangeEmailReqBody = {
  accountId: string;
  email: string;
};

export type DeleteAccountReqBody = {
  accountId: string;
};

export type DisconnectOauthAccountReqBody = {
  accountId: string;
  provider: keyof UserOAuthConnection;
};

export type ConnectOauthAccountReqBody = {
  accountId: string;
  provider: keyof UserOAuthConnection;
  socialId: string;
};

export type BackofficeQueryBody = {
  regType: AccountRegType;
  emailAddress?: string;
  accountName?: string;
  customerId?: string;
  phoneNumber?: string;
};

export type BackofficeQueryResponse = {
  totalCount: number;
  accountList: Array<AccountDetailsDTO>;
};

export type BackofficeReasonBody = {
  reason: 'User' | 'Operator';
  comment: string;
};

export type BackofficeAddSubscriptionReqBody = {
  subscriberNumber: string;
};

export type BackofficeAddSubscriptionByPhoneNumberReqBody = {
  phoneNumber: string;
};

export type BackofficeLostEmailReqBody = {
  email: string;
};

export type BackofficeLostEmailOtpReqBody = {
  otp: string;
};

export type ResetPasswordReqBody = {
  email: string;
};

export type LockAccountReqBody = {
  reason: LockReasonType;
};

export type UnlockAccountReqBody = {
  reason: LockReasonType;
};

export enum RegistrationMode {
  Default = 'default',
  Password = 'password',
  Yolo = 'yolo',
}

export type RegisterAccountReqBody = {
  mode: RegistrationMode.Default | RegistrationMode.Password | RegistrationMode.Yolo;
  name: string;
  email: string;
  password: string;
}

export type RegisterAccountResp = {
  accountId: string;
}

export type OTPConfirmReqBody = {
  accountId: string;
  otp: string;
}

export enum LockReasonType {
  Unknown = 'Unknown', //Ismeretlen
  User = 'User', //Ügyfél művelet eredménye
  System = 'System', //Rendszer művelet eredménye
  Operator = 'Operator', //Ügyintézői művelet eredménye
}

export type MFACheckSmsNumberReqBody = {
  phoneNumber: string;
};

export type MFAStoreSmsNumberReqBody = {
  phoneNumber: string;
};

export type MFACreateQrCodeReqBody = {
  authenticatorName: string;
  email: string;
};

export type MFAStoreQrCodeReqBody = {
  totpUrl: string;

};

export type MFACreateQrCodeResponse = {
  secret: string;
  totp: string;
  encoded: string;
};

export type TotpConnectReqBody = {
  totpUrl: string;
};
export type SmsConnectReqBody = {
  phoneNumber: string;
};

export type AccountConnectReqBody = {
  referer: string;
};

export type LoginUrlResp = {
  url: string;
  error?: string;
};

export type ImpersonationTarget = 'alm' | 'selfcare' | 'yettel.hu' | 'yettelTv';

export type ImpersonateUrlReqBody = {
  email: string;
  name: string;
  accountId: string;
  target: ImpersonationTarget;
}

export type SubscriptionIdReqBody = {
  subscriptionId: string;
};

export type CustomerIdReqBody = {
  customerId: string;
};

export type BlockEmailChangeReqQuery = {
  sessionId: string;
};

export type ImpersonationAuditBody ={
  accountId: string,
  email: string,
  target: ImpersonationTarget,
  boUser: string,
}

export type ConfirmEmailResp = {
  account: AccountDTO;
  isRegistrationFlow: boolean;
}
