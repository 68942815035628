import { Button, Input, Loader } from '@ytl/common-web';
import { ChangeEvent, useCallback, useState } from 'react';
import { useApp } from '../../../../contexts/appContext';
import { useLanguage } from '../../../../contexts/languageContext';
import { searchOnEnter } from '../../../utils/searchOnEnter';

export type AccountDataEditorType = 'email' | 'name';

export interface AccountDataEditorProps {
  label: string;
  label2?: string;
  value: string;
  type: AccountDataEditorType;

  viewMode: ViewMode;
  canModify: (type: AccountDataEditorType) => Promise<boolean>;
  canCancel: (type: AccountDataEditorType) => Promise<boolean>;
  showModify?: boolean;
  onSave: (type: AccountDataEditorType, value: string, value2?: string) => Promise<Array<string> | boolean>;
  onValueChange: (type: AccountDataEditorType) => void;
}

export enum ViewMode {
  View = 'View',
  Edit = 'Edit',
}

export const AccountDataEditor = ({
  label,
  label2,
  value,
  type,
  viewMode,
  canModify,
  canCancel,
  showModify,
  onSave,
  onValueChange,
}: AccountDataEditorProps) => {
  const { translation } = useLanguage();
  const { isLoading, setLoading } = useApp();
  const [editedValue, setEditedValue] = useState(value);
  const [editedValue2, setEditedValue2] = useState(value);
  const [errorList, setErrorList] = useState<Array<string>>([]);

  const modify = useCallback(async () => {
    const shouldContinue = await canModify(type);
    if (shouldContinue) {
      setLoading(false);
      setEditedValue(value);
      setEditedValue2(value);
    }
  }, [canModify, type, value, setLoading]);

  const updateClicked = useCallback(async () => {
    try {
      setLoading(true);
      const res = await onSave(type, editedValue, editedValue2);
      if (res instanceof Array) {
        setErrorList(res);
      } else {
        setErrorList([]);
      }
    } finally {
      setLoading(false);
    }
  }, [editedValue, onSave, setLoading, type, editedValue2]);

  const cancelEdit = useCallback(async () => {
    const shouldContinue = await canCancel(type);
    if (shouldContinue) {
      setEditedValue(value);
      setErrorList([]);
    }
  }, [canCancel, value, type]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEditedValue(e.target.value);
      onValueChange(type);
    },
    [onValueChange, type],
  );

  const onChange2 = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEditedValue2(e.target.value);
      onValueChange(type);
    },
    [onValueChange, type],
  );

  return (
    <>
      <div className={'account-data__editor'}>
        <Input
          label={label}
          name={'name'}
          disabled={viewMode === ViewMode.View || isLoading}
          value={viewMode === ViewMode.View ? value : editedValue}
          onChange={onChange}
          type={type === 'email' ? 'email' : 'text'}
          errorList={errorList}
          onKeyUp={e => {
            setErrorList([]);
            searchOnEnter(() => updateClicked())(e);
          }}
          {...(type === 'email' && { clearable: true, onClear: () => setEditedValue('') })}
        />

        {type === 'email' && viewMode === ViewMode.Edit && (
          <Input
            label={label2}
            className={'account-data__editor__input--email'}
            name={'name'}
            disabled={isLoading}
            value={editedValue2}
            onChange={onChange2}
            type={'email'}
            onKeyUp={e => {
              setErrorList([]);
              searchOnEnter(() => updateClicked())(e);
            }}
            clearable={true}
            onClear={() => setEditedValue2('')}
          />
        )}
      </div>

      <div className={'account-data__button-row'}>
        {viewMode === ViewMode.Edit && (
          <>
            <Button
              variant={'outlined'}
              size={'small'}
              onClick={cancelEdit}
              disabled={isLoading}
            >
              {translation.accountSettings.accountDataCard.cancelButton}
            </Button>
            <Button
              variant={'contained'}
              size={'small'}
              onClick={updateClicked}
              disabled={isLoading}
            >
              <Loader isLoading={isLoading} />
              {translation.accountSettings.accountDataCard.saveButton}
            </Button>
          </>
        )}
        {viewMode === ViewMode.View && showModify !== false && (
          <Button
            variant={'text'}
            onClick={modify}
          >
            {translation.accountSettings.accountDataCard.modifyButton}
          </Button>
        )}
      </div>
    </>
  );
};
