import { InputMaxPasswordLength } from '@ytl/common-web';

const minimumPasswordLength = 10;
export const maximumPasswordLength = InputMaxPasswordLength;

export type PasswordValidationResult = {
  passwordsAreMatching?: boolean;
  hasLowerCaseLetter?: boolean;
  hasUpperCaseLetter?: boolean;
  hasNumber?: boolean;
  hasSpecialCharacter?: boolean;
  lengthIsCorrect?: boolean;
};

export function passwordStrengthChecker(password: string, passwordAgain: string): PasswordValidationResult | undefined {
  if (!password && !passwordAgain) {
    return undefined;
  }
  return {
    passwordsAreMatching: !!password && !!passwordAgain ? password === passwordAgain : undefined,
    hasLowerCaseLetter: !!password ? password.match(/[a-z\p{Ll}]/gu) !== null : undefined,
    hasUpperCaseLetter: !!password ? password.match(/[A-Z\p{Lu}]/gu) !== null : undefined,
    hasNumber: !!password ? password.match(/[0-9]/) !== null : undefined,
    hasSpecialCharacter: !!password ? password.match(/[\p{P}\p{S}]/gu) !== null : undefined,
    lengthIsCorrect: !!password
      ? password.length >= minimumPasswordLength && password.length <= maximumPasswordLength
      : undefined,
  };
}
