import { Box, BoxProps, Button } from '@ytl/common-web';
import { useMemo } from 'react';
import { ReactComponent as CheckIcon } from '../../../assets/check.svg';
import { useLanguage } from '../../../contexts/languageContext';

export const useMfaSMSSuccessView = (email: string, onContinue: () => void) => {
  const { translation } = useLanguage();

  const boxConfig: Omit<BoxProps, 'children'> = useMemo(() => {
    return {
      icon: <CheckIcon className={'icon '} />,
      header: translation.accountSettings.mfa.smsSuccess.title,
      subHeader: translation.accountSettings.mfa.smsSuccess.content(email),
      action: <Button onClick={() => onContinue()}>{translation.accountSettings.mfa.smsSuccess.continue}</Button>,
    };
  }, [translation, email, onContinue]);

  const view = useMemo(() => <Box {...boxConfig} />, [boxConfig]);

  return {
    view,
  };
};
