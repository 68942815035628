import { Input } from '@ytl/common-web';
import { ReactNode } from 'react';
import { useLanguage } from '../../../../contexts/languageContext';
import { searchOnEnter } from '../../../utils/searchOnEnter';

export interface SetPasswordProps {
  password: string;
  passwordAgain: string;
  isLoading: boolean;
  passwordHints: ReactNode;
  hasPasswordError: boolean;
  passwordsAreNotMatching: boolean;
  setPassword: (value: string) => void;
  setPasswordAgain: (value: string) => void;
  onEnter: () => void;
}

export const SetPassword = ({
  password,
  passwordAgain,
  passwordHints,
  hasPasswordError,
  passwordsAreNotMatching,
  setPassword,
  setPasswordAgain,
  isLoading,
  onEnter,
}: SetPasswordProps) => {
  const { translation } = useLanguage();

  return (
    <>
      {/*{isAdhoc && checkOldPassword !== undefined && (*/}
      {/*  <Input*/}
      {/*    label={translation.passwordSet.passwordOriginal}*/}
      {/*    name={'old-password'}*/}
      {/*    type={'password'}*/}
      {/*    value={oldPassword}*/}
      {/*    onChange={e => checkOldPassword(e.target.value)}*/}
      {/*    required={true}*/}
      {/*    disabled={isLoading}*/}
      {/*    onKeyUp={searchOnEnter(() => onEnter())}*/}
      {/*  />*/}
      {/*)}*/}

      <Input
        label={translation.passwordSet.password}
        name={'password'}
        type={'password'}
        value={password}
        onChange={e => setPassword(e.target.value)}
        hasError={hasPasswordError || passwordsAreNotMatching}
        required={true}
        disabled={isLoading}
        onKeyUp={searchOnEnter(() => onEnter())}
      />

      <Input
        label={translation.passwordSet.passwordAgain}
        name={'password-again'}
        type={'password'}
        value={passwordAgain}
        onChange={e => setPasswordAgain(e.target.value)}
        hasError={hasPasswordError || passwordsAreNotMatching}
        errorList={passwordsAreNotMatching ? [translation.passwordSet.error.doesntMatch] : []}
        required={true}
        disabled={isLoading}
        onKeyUp={searchOnEnter(() => onEnter())}
      />

      <div className="password-requirements">{passwordHints}</div>
    </>
  );
};
