import { UserDTO } from '@ytl/common-model';
import { ReactElement, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCurrentUser } from '../contexts/currentUserSlicer';
import { useLanguage } from '../contexts/languageContext';
import { AppRoutes } from '../routes';
import { AccountSettings } from '../shared/components/partials/AccountSettings';
import { mfaResource } from '../shared/resource/mfa.resource';

export const HomePage = (): ReactElement => {
  const { translation } = useLanguage();
  const currentUser = useSelector(selectCurrentUser) as UserDTO;
  const navigate = useNavigate();

  const disconnectMFA = useCallback(async () => {
    await mfaResource.disconnect();
  }, []);

  return (
    <div className={`home-page`}>
      <h3 className="header-3">{translation.homePage.header.replace('$name$', currentUser.name)}</h3>

      <AccountSettings
        onSetupMFA={() => navigate(AppRoutes.mfaSetup)}
        onDisconnectMFA={disconnectMFA}
      />
    </div>
  );
};
